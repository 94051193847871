.profile-solid.icon {
    color: #000;
    position: absolute;
    margin-left: 3px;
    margin-top: 11px;
    width: 14px;
    height: 6px;
    border-left: solid 1px currentColor;
    border-right: solid 1px currentColor;
    border-top: solid 1px currentColor;
    border-bottom: solid 1px transparent;
    background-color: currentColor;
    border-radius: 6px 6px 0 0;
  }
  
  .profile-solid.icon:before {
    content: '';
    position: absolute;
    left: 2px;
    top: -10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: solid 1px currentColor;
    background-color: currentColor;
  }
  
  